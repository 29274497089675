import React from "react";

import useFunnelData from "../../FunnelApp/Hooks/useFunnelData";

import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Brush,
  AreaChart,
  Area,
  ResponsiveContainer,
} from "recharts";

export default function XChart({ meta }) {
  var appKey = meta?.AppKey;
  var platform = meta?.Platform;

  const { data } = useFunnelData(appKey, platform);
  var appName = appKey + "-" + platform;
  var keyMetric = "purchase-paid"; //meta?.KeyMetric ?? "purchase-paid";

  const weeks = data && data["week"];
  console.log(data);

  //const labels = weeks && Object.keys(weeks).map((item, index) => data["year"][item]+"-"+weeks[item]).reverse();
  const metrics =
    appKey &&
    weeks &&
    Object.keys(weeks)
      .map((item, index) => {
        var foundItems = Object.keys(data).filter((f) => f === keyMetric);
        return {
          week: data["week"][item],
          metric: foundItems.length !== 0 ? data[keyMetric][item] : 0,
        };
      })
      .reverse();

  return (
    <>
      {!metrics ? (
        <>Loading</>
      ) : (
        <AreaChart
          width={400}
          height={200}
          data={metrics}
          syncId="anyId"
          margin={{
            top: 0,
            right: 0,
            left: 0,
            bottom: 0,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="week" />
          <YAxis />
          <Tooltip />
          <Area
            type="monotone"
            dataKey="metric"
            stroke="#82ca9d"
            fill="#82ca9d"
          />
        </AreaChart>
      )}
    </>
  );
}
