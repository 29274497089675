import * as React from "react";

import { useCallback, useState, useEffect } from "react";
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useMatch,
} from "react-router-dom";

import useFunnelList from "../../FunnelApp/Hooks/useFunnelList";
import FunnelTable from "../../FunnelApp/Components/FunnelTable";
import XChart from "./XChart";

export default function FunnelList({ project }) {
  const { list, loading } = useFunnelList(project?.Id);

  return (
    <div className="m-5 w-full">
      <div className="flex flex-col">
        {loading ? (
          <>Loading...</>
        ) : (
          <>
            {list.map((funnel) => (
              <div className="my-4">
                <div className="font-medium">{funnel.Name}</div>
                <div>{funnel.AppKey + "-" + funnel.Platform}</div>
                <XChart
                  meta={funnel}
                  appKey={funnel.AppKey}
                  platform={funnel.Platform}
                />
                <FunnelTable meta={funnel} />
              </div>
            ))}
          </>
        )}
      </div>
    </div>
  );
}
