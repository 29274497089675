import * as React from "react";

import { useCallback, useState, useEffect } from "react";

export default function RuleView({ rule, column, store }) {
  const handleOnBlur = (e) => {
    const attributeName = e.target.id;
    let attributeValue = e.target.value.trim();
    // ---
    console.log(attributeName + " " + attributeValue);
    store.UpdateRule(rule.Id, attributeName, attributeValue);
  };

  return (
    <div>
      <div className="flex" direction="row" spacing={2}>
        <input
          type="text"
          onBlur={handleOnBlur}
          defaultValue={rule && rule.Metric}
          id="metric-name"
          name="metric-name"
          class="bg-gray-50 my-auto mx-2 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Metric"
          required
        />

        <input
          type="text"
          id="metric-value"
          name="metric-value"
          onBlur={handleOnBlur}
          defaultValue={rule && rule.Value}
          class="bg-gray-50 my-auto mx-2 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Value"
          required
        />

        <button
          onClick={(e) => store.DeleteRule(rule.Id)}
          type="button"
          class="py-2 px-5  me-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
        >
          Delete
        </button>
      </div>
    </div>
  );
}
