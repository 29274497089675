import * as React from "react";
import { useCallback, useState, useEffect } from "react";

export default function MetricView({ column, store }) {
  const handleOnBlur = (e) => {
    const columnId = column.Id;
    const attributeName = e.target.id;
    let attributeValue = e.target.value.trim();

    console.log(columnId + " " + attributeName + " " + attributeValue);
    store.UpdateColumn(columnId, attributeName, attributeValue);
  };

  return (
    <div>
      <div className="flex" direction="row" spacing={2}>
        <input
          type="text"
          id="name"
          name="name"
          onBlur={handleOnBlur}
          defaultValue={column.Name}
          class="bg-gray-50 mx-2 my-1 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Platform"
          required
        />

        <button
          onClick={(e) => store.DeleteColumn(column.Id)}
          type="button"
          class="py-2 mx-2 px-5 mx-2 me-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
        >
          Delete
        </button>
      </div>
    </div>
  );
}
