import * as React from "react";

import { useCallback, useState, useEffect } from "react";

export default function KeyMetricView({ funnel, store }) {
  const handleOnBlur = (e) => {
    const attributeName = e.target.id;
    let attributeValue = e.target.value.trim();
    // ---
    console.log(attributeName + " " + attributeValue);
    store.Update(attributeName, attributeValue);
  };

  return (
    <div>
      <div className="flex" direction="row" spacing={2}>
        <input
          type="text"
          onBlur={handleOnBlur}
          defaultValue={funnel && funnel.KeyMetric}
          id="keymetric"
          name="keymetric"
          class="bg-gray-50 my-auto mx-2 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Key Metric"
          required
        />
      </div>
    </div>
  );
}
