import * as React from "react";

import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useMatch,
} from "react-router-dom";

import useFunnelData from "./Hooks/useFunnelData";
import FunnelTable from "./Components/FunnelTable";
import FunnelEditor from "./Components/FunnelEditor";
import FunnelStore from "./Store/FunnelStore";
import useFunnelGet from "./Hooks/useFunnelGet";
import useFunnelSave from "./Hooks/useFunnelSave";
import LoadingControl from "../../components/Core/LoadingControl/LoadingControl";

import { EditFunnel } from "../FunnelApp/Api/FunnelClient";

import TagsView from "./Components/TagsView";

export default function FunnelEx() {
  const navigate = useNavigate();
  const location = useLocation();

  /*
  const {
    params: { projectId, funnelId },
  } = useMatch("/project/:projectId/funnel/:funnelId");
  */

  var template = location.pathname.includes("create")
    ? "/project/:projectId/funnels/create"
    : "/project/:projectId/funnel/:funnelId";

  const match = useMatch(template);
  var projectId = match && match.params && match.params.projectId;
  var funnelId = match && match.params && match.params.funnelId;

  console.log(location.pathname);
  console.log(projectId + " - " + funnelId);

  //####
  const { meta, setMeta, loading } = useFunnelGet(projectId, funnelId);
  const appKey = meta?.AppKey;
  const platform = meta?.Platform;

  //####
  const { data } = useFunnelData(appKey, platform);
  var originalColumns = Object.getOwnPropertyNames(data);

  const { funnelSave, saving } = useFunnelSave(meta);

  let store = new FunnelStore(meta, setMeta);

  const handleDelete = () => {
    var result = window.confirm("Please confirm to delete?");
    if (!result) return;

    var metaEx = { ...meta, RecordState: 1 };
    const response = EditFunnel(metaEx).then((response) => {
      navigate(`/project/${meta.ProjectId}/funnels`);
    });
  };

  return (
    <div className="m-5">
      <div class="flex flex-row">
        <div className="mx-2 flex-none">
          <button
            onClick={funnelSave}
            className="bg-white hover:bg-gray-100 text-gray-800 py-2 px-4 border border-gray-400 rounded shadow"
          >
            {saving ? <>Saving ...</> : <>Save</>}
          </button>
        </div>
        <div className="grow" />
        <div className="mx-2 flex-none">
          <button
            onClick={handleDelete}
            className="bg-white mx-2 hover:bg-gray-100 text-gray-800 py-2 px-4 border border-gray-400 rounded shadow"
          >
            Delete
          </button>
        </div>
      </div>

      <div className="m-2">
        {loading ? (
          <LoadingControl />
        ) : (
          <>
            <FunnelEditor meta={meta} store={store} />

            <div className="my-2">
              <TagsView
                appKey={appKey}
                platform={platform}
                tags={originalColumns}
              />
            </div>

            <FunnelTable meta={meta} data={data} />
          </>
        )}
      </div>
    </div>
  );
}
