import * as React from "react";

import metric_icon from "../Assets/Images/metric.png"; //'/../Assets/Images/project_icon.png';
import { useState } from "react";

import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
} from "@headlessui/react";
import {
  ExclamationTriangleIcon,
  BeakerIcon,
  BugAntIcon,
} from "@heroicons/react/24/outline";
import FunnelTable from "./FunnelTable";
import useFunnelData from "../Hooks/useFunnelData";

export default function ItemView({ funnel }) {
  const url = `/project/${funnel.ProjectId}/funnel/${funnel.Id}`;
  const { data } = useFunnelData(funnel.AppKey, funnel.Platform);
  const [open, setOpen] = useState(false);

  return (
    <>
      <a href={url} oclass="block relative">
        <li class="flex flex-row">
          <div class="select-none cursor-pointer hover:bg-gray-50 flex flex-1 items-center p-2">
            <div class="flex flex-col w-10 h-10 justify-center items-center mr-4">
              <img
                alt="profil"
                src={metric_icon}
                class="mx-auto object-cover rounded-md h-14 w-14"
              />
            </div>

            <div class="flex-1 pl-1">
              <div class="font-medium dark:text-white text-sm">
                {funnel.Name}
              </div>
              <div class="text-gray-600 dark:text-gray-200 text-sm">
                {funnel.AppKey} {funnel.Platform}
              </div>
            </div>

            <div class="flex-none mx-3">
              <div class="text-sm font-medium">Status</div>
              <div class="text-center text-sm">
                <div className={true ? "text-red-600" : "text-yellow-600"}>
                  failed
                </div>
              </div>
            </div>

            <div class="flex-none mx-3">
              <div class="text-sm font-medium">Metric</div>
              <div class="text-gray-600 text-center dark:text-gray-200 text-sm">
                5.48
              </div>
            </div>

            <div class="flex-none mx-3">
              <a
                href={"#"}
                onClick={() => setOpen(true)}
                type="button"
                class="py-2.5 px-5 me-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
              >
                Open
              </a>
            </div>
          </div>
        </li>
      </a>

      <Dialog open={open} onClose={setOpen} className="relative z-10">
        <DialogBackdrop
          transition
          className="fixed inset-0 bg-gray-500/75 transition-opacity data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in"
        />

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <DialogPanel
              transition
              className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all data-[closed]:translate-y-4 data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-200 data-[enter]:ease-out data-[leave]:ease-in sm:my-8 sm:w-full sm:max-w-lg sm:p-6 data-[closed]:sm:translate-y-0 data-[closed]:sm:scale-95"
            >
              <div>
                <div className="mx-auto flex size-12 items-center justify-center rounded-full bg-green-100">
                  {funnel.Name}
                </div>

                <div className="mt-3 text-center sm:mt-5">
                  <div className="mt-2">
                    <FunnelTable meta={funnel} data={data} />
                  </div>
                </div>
              </div>
              <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                <button
                  type="button"
                  data-autofocus
                  onClick={() => setOpen(false)}
                  className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                >
                  Cancel
                </button>
              </div>
            </DialogPanel>
          </div>
        </div>
      </Dialog>
    </>
  );
}
