import { useCallback, useState, useEffect } from "react";
import toast from "react-hot-toast";

import { GetProject } from "../Api/ProjectClient";

import { selectProject } from "./../../../redux/project/selectors";
import { setRexuxProject } from "./../../../redux/project/slice";

import { useDispatch, useSelector } from "react-redux";

import { ReactSession } from "react-client-session";
ReactSession.setStoreType("localStorage");

export default function useProjectGet(projectId, reload) {
  const [project, setProject] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    setLoading(true);

    if (!projectId) {
      const defaultState = {
        Id: "",
        Name: "",
        Tags: [],
        Logo: "",
        Status: 0,
        RecordState: 0,
      };
      setProject(defaultState);
      setLoading(false);
      return;
    }

    var cacheKey = "projectId-" + projectId;
    const project = ReactSession.get(cacheKey);
    if (project && !reload) {
      console.log("get project from Cache");
      setProject(project);
      setLoading(false);
      return;
    }

    GetProject(projectId).then((response) => {
      setProject(response.data);
      setLoading(false);

      ReactSession.set(cacheKey, response.data);
      console.log("Put project to Cache");
    });
  }, [projectId]);

  return { project, setProject, loading, error };
}
