import * as React from "react";

import { useCallback, useState, useEffect } from "react";

import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";

export default function TagsView({ appKey, platform, tags }) {
  return (
    <div>
      <h4 className="font-medium">
        {appKey}-{platform}
      </h4>
      <Stack direction="row" spacing={1}>
        {tags?.map((column) => (
          <Chip label={column} variant="outlined" />
        ))}
      </Stack>
    </div>
  );
}
