import * as React from "react";

import ColumnView from "./MetricSet/MetricView";
import RuleSetView from "./RuleSet/RuleSetView";
import TagsView from "./TagsView";
import MetricSetView from "./MetricSet/MetricSetView";
import KeyMetricView from "./KeyMetric/KeyMetricView";

export default function FunnelEditor({ meta, store, save }) {
  const handleOnBlur = (e) => {
    const attributeName = e.target.id;
    let attributeValue = e.target.value.trim();
    console.log(attributeName + " " + attributeValue);
    store.Update(attributeName, attributeValue);
  };

  return (
    <div>
      <div className="m-2">
        <div className="flex">
          <div class="mb-6">
            <label
              for="name"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Name
            </label>
            <input
              type="text"
              id="name"
              name="name"
              onBlur={handleOnBlur}
              defaultValue={meta && meta.Name}
              class="bg-gray-50 mx-2 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Name"
              required
            />
          </div>
        </div>

        <div className="flex">
          <div class="mb-6">
            <label
              for="appkey"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              App Key
            </label>
            <input
              type="text"
              id="appkey"
              name="appkey"
              onBlur={handleOnBlur}
              defaultValue={meta && meta.AppKey}
              class="bg-gray-50 mx-2 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="App Key"
              required
            />
          </div>

          <div class="mb-6 mx-2">
            <label
              for="platform"
              class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Platform
            </label>
            <input
              type="text"
              id="platform"
              name="platform"
              onBlur={handleOnBlur}
              defaultValue={meta && meta.Platform}
              class="bg-gray-50 mx-2 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Platform"
              required
            />
          </div>
        </div>

        {/* #### */}
        <h4 className="font-medium">Columns</h4>
        <MetricSetView metrics={meta?.Metrics} store={store} />

        {/* #### */}
        <h4 className="font-medium">Key Metric</h4>
        <KeyMetricView funnel={meta} store={store} />

        {/* #### */}
        <h4 className="font-medium">Rules</h4>
        <RuleSetView rules={meta?.Rules} store={store} />
      </div>
    </div>
  );
}
