import { useCallback, useState, useEffect } from "react";
import toast from "react-hot-toast";
import { GetProject } from "../api/ProjectClient";

export default function useProjectGet(projectId) {
  const [project, setProject] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);

    GetProject(projectId).then((response) => {
      setProject(response.data);
      console.log(response.data);
      setLoading(false);
    });
  }, [projectId]);

  return { project, loading };
}
